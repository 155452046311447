@font-face {
  font-family: 'CirceRegular';
  src: url("./assets/fonts/font-Circe/Circe-Regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.App {
  font-family: CirceRegular, sans-serif;
  text-align: center;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: #F5F8FB;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

body, html  {
  font-family: CirceRegular, sans-serif;
  position: relative;
  overflow-x: hidden;
}
.ol-viewport{
  background: #F5F8FB;
}